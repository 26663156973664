.thumbs {
  padding: 0px !important;
}

.tabulator .tabulator-header, .tabulator .tabulator-header .tabulator-col{
  overflow:inherit
}
.tabulator-row .tabulator-responsive-collapse table {
    font-size: 9px;
}
.tabulator-cell .tabulator-row-handle {
  display: inline
}
